<template>
  <b-container fluid>
    <b-row>
      <b-modal id="modal-5" size="md" title="Remove Project" hide-footer>
        <p class="font-size-18 text-black">Are you sure you want to delete this Lead? </p>
        <div class="d-flex justify-content-end">
          <b-button variant="danger" @click="deleteLead">Delete Lead</b-button>
        </div>
      </b-modal>
      <b-modal id="statusHistory" size="lg" title="History" show hide-footer>
        <statusHistory :allHistory="history" :historyId="historyId"/>
      </b-modal>
      <b-col lg="9">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dashboard2.leads')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if='loadingOpenAdd'  variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
              openning...
            </b-button>
            <b-button v-else v-b-modal.modal-all-lead variant="primary" @click="loadData">Add Lead</b-button>
            <b-modal size="lg" id="modal-all-lead" :title="modelTitle" hide-footer>
              <addLeadModel :allFeatures="allFeatures" :edit="editFlag"/>
            </b-modal>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else striped responsive :fields="columns" :items="getAllLeads" class="mb-0 table-borderless"  sort-icon-left primary-key="name" id="table-transition-example" :tbody-transition-props="transProps">
                            <template v-slot:cell(source)="data">
                             {{data.item.source.title}}
                            </template>
                          <template v-slot:cell(last_call_action)="data1">
                             {{data1.item.last_call_action==null? 'ـــــ' :  data1.item.last_call_action.substr(0,10)}}
                            </template>
              <template v-slot:cell(comment)="data2">
                {{data2.item.comment==null? 'ـــــ' :  data2.item.comment}}
              </template>
              <template v-slot:cell(phone)="data1">
                <p class="holdSelection" v-if="data1.item.id==showPhone">{{data1.item.phone}}</p>
                <b-button v-else variant=" iq-bg-success mr-1 mb-1" size="sm" :id="data1.item.id" @click="showPhone = data1.item.id" ><i class="las la-mobile"></i>Show Phone</b-button>
              </template>
              <template v-slot:cell(actions)="data1">
                <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showHistory(data1.item.id)" ><i class="las la-history"></i> Show History</b-button>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="changeContent"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="3">
        <iq-card>
          <template v-slot:body>
              <ul class="d-flex justify-content-around cursor-pointer">
                <li><span>Daily</span></li>
                <li><span>Weekly</span></li>
                <li><span>Monthly</span></li>
              </ul>
            <div class="m-0 p-0 d-flex flex-wrap align-items-center justify-content-between">
              <div class="col-md-12">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 like-block"></div>
                  <div class="iq-scale-content">
                    <h6>2.864</h6>
                    <p class="mb-0">Leads</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex align-items-center  mt-4">
                  <div class="iq-scale-border mr-3 comment-block"></div>
                  <div class="iq-scale-content">
                    <h6>624</h6>
                    <p class="mb-0">Facebook</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 share-block"></div>
                  <div class="iq-scale-content">
                    <h6>1.75</h6>
                    <p class="mb-0">Instagram</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex align-items-center mt-4">
                  <div class="iq-scale-border mr-3 repost-block"></div>
                  <div class="iq-scale-content">
                    <h6>345</h6>
                    <p class="mb-0">Complaints</p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
const addLeadModel = () => import('../../components/leads/leadsModal')
const statusHistory = () => import('../../components/statusHistory/statusHistory')
export default {
  name: 'leads',
  data () {
    return {
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      currentPage: 1,
      per_page: 10,
      total: 6,
      modelTitle: 'Add Lead',
      editFlag: false,
      loadingOpenAdd: false,
      loadTable: true,
      switchButton: [],
      showPhone: '',
      deleteId: '',
      history: [],
      historyId: '',
      columns: [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        /*  { label: 'Phone', key: 'phone', class: 'text-left', sortable: true }, */
        { label: 'Ad Source', key: 'source', class: 'text-left', sortable: true },
        { label: 'Last Action', key: 'last_call_action', class: 'text-left', sortable: true },
        { label: 'Comment', key: 'comment', class: 'text-left', sortable: true },
        { label: 'History', key: 'actions', class: 'text-left' }
      ],
      allFeatures: {}
    }
  },
  computed: {
    ...mapGetters(['getAllLeads'])
  },
  methods: {
    show (id) {
      document.getElementsByClassName('phone_' + id)[0].style.display = 'block'
      document.getElementsByClassName('show_' + id)[0].style.display = 'none'
    },
    hide (id) {
      document.getElementsByClassName('phone_' + id)[0].style.display = 'none'
      document.getElementsByClassName('show_' + id)[0].style.display = 'block'
    },
    changeContent () {
      this.loadTable = true
      this.$store.dispatch('getAllLead', { pageNumber: this.currentPage }).then(() => {
        // eslint-disable-next-line no-undef
        this.loadTable = false
      })
    },
    loadData () {
      this.loadingOpenAdd = true
      this.editFlag = false
      this.modelTitle = 'Add Lead'
      this.$store.dispatch('getInfoToCreateLead').then((data) => {
        this.$bvModal.show('modal-all-lead')
        console.log(data.data)
        this.allFeatures = data.data.data
        this.loadingOpenAdd = false
      })
    },
    gitDataToEdit (id) {
      this.editFlag = true
      // eslint-disable-next-line no-unused-expressions,no-sequences
      this.modelTitle = 'Edit Lead'
      this.$store.dispatch('GetSpacificProject', id).then(() => {
        this.$bvModal.show('modal-all-lead')
      })
    },
    setDeleteId (id) {
      this.deleteId = id
      this.$bvModal.show('modal-5')
    },
    deleteLead () {
      this.$store.dispatch('deleteLead', this.deleteId).then(() => {
        this.$bvModal.hide('modal-5')
        core.showSnackbar('success', 'Lead has been Edit successfully.')
      })
    },
    print (id) {
      console.log(id)
    },
    showHistory (id) {
      this.historyId = id
      this.$store.dispatch('gitHistory', { id: id, pageNumber: 1 }).then(res => {
        this.history = res.data.data.data
        this.$bvModal.show('statusHistory')
      })
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    addLeadModel,
    // eslint-disable-next-line vue/no-unused-components
    statusHistory
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadTable = true
    this.$store.dispatch('getAllLead', { pageNumber: this.currentPage }).then(res => {
      console.log(res)
      this.currentPage = res.data.meta.currentPage
      this.per_page = res.data.meta.per_page
      this.total = res.data.meta.total
      this.loadTable = false
    })
  }
}
</script>
<style scoped>
.colorSpan{
  width: 20px;
  height: 20px;
}
</style>
